import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Navigation from './components/Navigation';
import Chatroom from './components/Chatroom';
import StudyLab from './components/StudyLab';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom'

const Home = () => {
  return <div>
    <Navigation />
    <Outlet />
  </div>
}

function App() {
  return (
    // router references https://github.com/remix-run/react-router/blob/dev/examples/basic/src/App.tsx
    <Router>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/chatroom" exact element={<Chatroom />} />
          <Route path="/studylab" exact element={<StudyLab />} />
        </Route>
      </Routes>
    </Router >
  );
}

export default App;
