import React from 'react';
import { Component } from 'react'
import { Nav, Navbar, Container } from 'react-bootstrap'

class Navigation extends Component {
  render() {
    return (
      <Navbar bg="light" sticky="top" expand="md" collapseOnSelect>
        <Container>

          <Navbar.Brand href="/">
            im1 World ai
          </Navbar.Brand>

          <Navbar.Toggle aria-controls='basic-navbar-nav' />

          <Navbar.Collapse>
            <Nav>
              <Nav.Link href="chatroom">CHATROOM</Nav.Link>
              <Nav.Link href="studylab">STUDY LAB </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default Navigation