import React from 'react'
import { Component } from 'react'
import { Container, Form, Button, Card } from 'react-bootstrap'
const { Configuration, OpenAIApi } = require("openai");



class Chatroom extends Component {
  constructor() {
    super()
    this.state = {
      heading: 'The words from the im1 Don ai ...',
      response: '...thanks your waiting'
    }
  }

  onFormSubmit = e => {
    // Start by Preventing the Default
    e.preventDefault()

    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries())
    console.log(formDataObj.productName)

    // OpenAi
    const configuration = new Configuration({
      apiKey: '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',
    });
    const openai = new OpenAIApi(configuration);

    openai.createCompletion({
      model: 'text-davinci-003',
      prompt: `tell me about you, and what you, im1 ai,  can do for ${formDataObj.productName} \n `,
      temperature: 0.7,
      max_tokens: 200,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    })

      .then((response) => {
        this.setState({
          heading: `You are saying: ${formDataObj.productName}`,
          response: `${response.data.choices[0].text}`
        })
      })

  }
  render() {
    return (
      <div>
        <Container>
          <br />
          <h2> connecting with Ai world</h2>
          <br />
          <Form onSubmit={this.onFormSubmit}>
            <Form.Group className="mb-3" controlId="aitalk">
              <Form.Label>What do you talk with im1 ai?</Form.Label>
              <Form.Control
                type="text"
                name="productName"
                placeholder="You:" />
              <br />
              <Form.Text className="text-muted">
                Say same things we know each other.
              </Form.Text>
            </Form.Group>
            <br />
            <Button variant="primary" size="lg" type="submit">
              Let us happy:
            </Button>

          </Form>

          <br />

          <Card>
            <Card.Body>
              <Card.Title><h2>{this.state.heading}</h2></Card.Title>
              <br />
              <Card.Text>
                <h4>
                  {this.state.response}
                </h4>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
        <br />
        <br />

      </div>
    )
  }
}

export default Chatroom
