import React, { useState } from 'react'
import { Button, Form, Container, Stack } from 'react-bootstrap'
import Questions from '../data/questions.json'
import { openai } from '../open-ai'

const lenQuestions = Questions.length

const StudyLab = () => {
  // TODO: use mobx
  const [studyMatt, setStudyMatt] = useState('')

  const [question, setQuestion] = useState(Questions[0])
  // AI answer
  const [answer, setAnswer] = useState('')
  const [myAnswer, setMyAnswer] = useState('')

  const submit = () => {
    openai.createCompletion({
      model: "text-davinci-003",
      prompt: `${studyMatt}, \n ${question}`,
      temperature: 0.46,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    })
      .then((response) => {
        setAnswer((response.data.choices[0].text ?? '').trim())
      })

  }

  const pickRandomQuestion = () => {
    const index = Math.floor(Math.random() * (lenQuestions))
    setQuestion(Questions[index])
  }


  return (
    <Container >
      <Form.Group className="mb-3"         >
        <Form.Label>Study Material:</Form.Label>  <Form.Control
          as="textarea"
          rows='6'
          name='studyMatt'
          value={studyMatt}
          onChange={e => setStudyMatt(e.target.value)}
          placeholder="Please paste some study material here..." />
      </Form.Group>

      <Stack direction='horizontal'>
        <Form.Group className='me-auto' style={{ flex: 1 }} >
          <Form.Label>Question:</Form.Label>
          <Form.Control
            as="textarea"
            rows='3'
            name='studyQuestion'
            value={question}
            onChange={e => setQuestion(e.target.value)}
            placeholder="Pick a question" />
        </Form.Group>
      </Stack>
      <Stack direction="horizontal">
        <Stack direction='horizontal' gap={2} className='flex-fill justify-content-center my-2'>
          <Button onClick={pickRandomQuestion}><i class="bi bi-shuffle me-2"></i>Random Question</Button>
        </Stack>

        <Button variant="primary" onClick={submit}>
          <i class="bi bi-send pe-2"></i>
          Submit
        </Button></Stack>

      <Form.Group className='my-2' >
        <Form.Label>AI Answer:</Form.Label>
        <Form.Control
          as="textarea"
          placeholder='Waiting for AI to respond...'
          rows='6'
          name='aiAnswer'
          value={answer} />
      </Form.Group>
      <Form.Group className='my-2'  >
        <Form.Label>Not as expected? Type in your answer below:</Form.Label>
        <Form.Control
          as="textarea"
          rows='6'
          name="myAnswer"
          onChange={(e) => setMyAnswer(e.target.value)}
          value={myAnswer} />
      </Form.Group><Stack direction="horizontal" className='justify-content-end'>
        <Button variant="primary">
          <i class="bi bi-send pe-2"></i>Send
        </Button>
      </Stack>
    </Container>
  )

}

export default StudyLab

